import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  update_approval_status,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";
import { useParams } from "react-router-dom";

const VerifyCertificateModal = (props) => {
  const { handle_modal_close, type, get_approval_status } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id, society_id } = useParams();

  const { status, error, loading } = useDynamicSelector("updateApprovalStatus");

  useEffect(() => {
    if (status === "Success") {
      showToast({ type: "success", message: "Status updated successfully" });
      dispatch(dynamicClear("updateApprovalStatus"));
      handle_modal_close();
      get_approval_status()
    } else if (error) {
      showToast({
        type: "error",
        message: error?.message,
      });
      dispatch(dynamicClear("updateApprovalStatus"));
    }
    return () => {
      form.resetFields();
    };
  }, [status, error, type]);

  const on_finish = ({ society_code, reason_to_reject }) => {
    let keys = [{ key: "updateApprovalStatus", loading: true }];
    let variable = {
      user_id: society_id,
      user_excel_id: id,
      society_code,
      reason_to_reject,
      type,
    };
    dispatch(dynamicRequest(keys, update_approval_status, variable));
  };

  return (
    <div>
      <h3>{type === "reject" ? "Reject Certificate" : "Verify Certificate"}</h3>

      <Form
        layout="vertical"
        onFinish={on_finish}
        className="health_check_form"
        form={form}
      >
        <Form.Item
          label="Society Code"
          name="society_code"
          rules={[
            { required: true, message: "Please enter your society code" },
          ]}
        >
          <Input placeholder="Society Code" />
        </Form.Item>

        {type === "reject" && (
          <Form.Item
            label="Reason for rejection"
            name="reason_to_reject"
            rules={[
              {
                required: true,
                message: "Please enter your reason for rejection",
              },
            ]}
          >
            <Input.TextArea placeholder="Reason for rejection" />
          </Form.Item>
        )}

        <Form.Item
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerifyCertificateModal;
