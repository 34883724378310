import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
// import "antd/dist/antd.min.css";
import _grid from "./grid";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Upload,
  Cascader,
} from "antd";
import JoditEditor from "jodit-react";
import { Divider, HStack } from "native-base";
import { useEffect, useState } from "react";
import lodash from "lodash";
import axios from "axios";
import { QueryRequest } from "@services/apollo/api_service";
import { gql } from "@apollo/client";
import { showToast } from "@helpers/toast";
import TextEditor from "../quill_editor/quill_editor";
const { TextArea } = Input;
const { Option } = Select;

const TextBox = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Input disabled={props.disabled} defaultValue={props.defaultValue} />
    </Form.Item>
  );
};

const HTMLEditor = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TextEditor />
    </Form.Item>
  );
};
const FormSwitch = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="checked"
    >
      <Switch disabled={props.disabled} />
    </Form.Item>
  );
};
const SelectSearch = (props) => {
  let options = props.options;
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Select
        showSearch
        allowClear
        mode={props.isMulti ? "multiple" : "single"}
        onSearch={props.onSearch}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        disabled={props.disabled}
      >
        {options?.map((item) => {
          if (item)
            return (
              <Option value={item[props.valueField]}>
                {item[props.labelField]}
              </Option>
            );
        })}
      </Select>
    </Form.Item>
  );
};

const Cascade = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <Cascader
        options={props.options}
        loadData={props.loadData}
        onChange={props.onChange}
        changeOnSelect
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
const Number = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <InputNumber
        style={{ width: "100%" }}
        controls={false}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
      />
    </Form.Item>
  );
};

const FormTextArea = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TextArea disabled={props.disabled} />
    </Form.Item>
  );
};
const FormSelect = (props) => {
  let options = props.options;
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Select
        mode={props.isMulti ? "multiple" : "single"}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        showSearch={props.isSearchable}
        onChange={props.onChange}
        allowClear={props.allowClear}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options?.map((item, index) => {
          let value = lodash.get(item, props.valueField || "id", null);
          let label = lodash.get(item, props.labelField || "name", null);
          return (
            <Option value={value} key={index}>
              {label}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
const Password = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Input.Password disabled={props.disabled} />
    </Form.Item>
  );
};

const fileQuery = gql`
  query getUploadUrl($file_name: String) {
    getUploadUrl(file_name: $file_name) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
let fileUploadresponse;
const File = (props) => {
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async ({ name, type }) => {
    let fileupload = {
      type: "booklet",
      fileName: name,
    };
    fileUploadresponse = await QueryRequest(fileQuery, fileupload);
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        url: fileUploadresponse?.data?.getUploadUrl?.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url = fileUploadresponse?.data?.getUploadUrl?.url?.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = fileUploadresponse?.data?.getUploadUrl;
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
    >
      <Upload
        maxCount={props.maxCount}
        action={action}
        headers={headers}
        fileList={fileList}
        disabled={props.isdisabled}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={(args) => handleBeforeUpload(args)}
        onChange={(e) => handleFileChanged(e)}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
};
const MonthPicker = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <DatePicker
        picker="month"
        disabledDate={props.disabledDate}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
const Date = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <DatePicker
        disabledDate={props.disabledDate}
        disabled={props.disabled}
        picker={props.picker}
      />
    </Form.Item>
  );
};
const FormRadio = (props) => {
  {
    let options = props.options.map((item) => {
      return <Radio value={item["id"]}>{item["name"]}</Radio>;
    });
    return props.type === "button" ? (
      <Form.Item label={props.label} name={props.field}>
        <Radio.Group>
          {props.options.map((item) => {
            return (
              <Radio.Button value={item["id"]}>{item["name"]}</Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    ) : (
      <Form.Item label={props.label} name={props.field}>
        <Radio.Group>
          {props.direction === "row"
            ? props.options.map((item) => {
                return (
                  <Row justify="space-between">
                    <Radio value={item["id"]}>{item["name"]}</Radio>
                  </Row>
                );
              })
            : options}
        </Radio.Group>
      </Form.Item>
    );
  }
};
const CheckBox = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} valuePropName="checked">
      <Checkbox
        style={{
          lineHeight: "32px",
        }}
        disabled={props.disabled}
      />
    </Form.Item>
  );
};
const RichText = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <JoditEditor />
    </Form.Item>
  );
};
const FormButton = (props) => {
  return (
    <Form.Item
      wrapperCol={{
        span: 12,
        offset: 6,
      }}
    >
      <Button type="primary" htmlType="submit" loading={props.isLoading}>
        {props.children}
      </Button>
    </Form.Item>
  );
};
const Grid = (props) => {
  return <_grid {...props} />;
};
const List = (props) => {
  return (
    <Form.List name={props.field_name}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ width: "80%", marginLeft: " 10%" }}>
            <Form.Item>
              <Button
                type="solid"
                onClick={() => add()}
                style={{ width: "20%", float: "right" }}
              >
                <PlusOutlined /> Add field
              </Button>
            </Form.Item>
            {fields.map((field, index) => (
              <div key={field.key}>
                {props.children.map((item) => {
                  let on_item = {
                    ...item,
                    props: {
                      ...item.props,
                      field: [index, `${item?.props?.field}`],
                    },
                  };
                  return on_item;
                })}
                {fields.length > 1 ? (
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Above Field
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
          </div>
        );
      }}
    </Form.List>
  );
};

const AntdDynamicForm = (props) => {
  const { children } = props;
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [form, props.initialValues]);
  return (
    <Form
      form={form}
      name={props.id}
      layout={"vertical"}
      onFinish={props.onSubmit}
      onValuesChange={props.onValueChange}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
};
AntdDynamicForm.Grid = Grid;
AntdDynamicForm.List = List;
AntdDynamicForm.TextBox = TextBox;
AntdDynamicForm.HTMLEditor = HTMLEditor;
AntdDynamicForm.Number = Number;
AntdDynamicForm.TextArea = FormTextArea;
AntdDynamicForm.Password = Password;
AntdDynamicForm.Phone = Number;
AntdDynamicForm.Select = FormSelect;
AntdDynamicForm.Cascade = Cascade;
AntdDynamicForm.Search = SelectSearch;
AntdDynamicForm.MonthPicker = MonthPicker;
AntdDynamicForm.RichText = RichText;
AntdDynamicForm.File = File;
AntdDynamicForm.Date = Date;
AntdDynamicForm.Radio = FormRadio;
AntdDynamicForm.Switch = FormSwitch;
AntdDynamicForm.CheckBox = CheckBox;
AntdDynamicForm.Button = FormButton;
export default AntdDynamicForm;
