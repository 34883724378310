import { gql } from "@apollo/client";

export const get_society_list = gql`
  query getSystemUserList {
    getSystemUserList {
      items {
        id
        username
        name
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_society_ticket_list = gql`
  query getSocietyTickets(
    $district_id: String
    $page_limit: Int
    $page_number: Int
    $search_string: String
    $raised_date_from: String
    $raised_date_to: String
    $ticket_status: String
    $sort_by: String
    $sort_order: String
  ) {
    getSocietyTickets(
      district_id: $district_id
      page_limit: $page_limit
      page_number: $page_number
      search_string: $search_string
      raised_date_from: $raised_date_from
      raised_date_to: $raised_date_to
      ticket_status: $ticket_status
      sort_by: $sort_by
      sort_order: $sort_order
    ) {
      items {
        id
        raised_on
        ticket_number
        raised_for {
          id
          username
          name
          district {
            name
          }
        }
        features{
          id 
          feature_name
          feature_description
        }
        resolved_on
        issue_raised_through
        type_of_ticket
        ticket_status
        description
        internal_description
        screenshot_url
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const mutation_create_society_ticket = gql`
  mutation createSocietyTicket($data: society_ticket_input) {
    createSocietyTicket(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_society_ticket = gql`
  mutation updateSocietyTicket(
    $ticket_id: String!
    $ticket_status: String!
    $internal_description: String!
  ) {
    updateSocietyTicket(
      ticket_id: $ticket_id
      ticket_status: $ticket_status
      internal_description: $internal_description
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const new_feature_request_query = gql`
 query getNewFeatureRequestList {
  getNewFeatureRequestList {
    items {
      id
      feature_name
      feature_description
    }
  }
}
`;