import AdminLayout from "@views/layouts/admin_layout";

import Redirector from "@views/pages/redirector";
import Login from "@views/pages/login/login";
import Dashboard from "@views/pages/dashboard/dashboard";
import SocietyPage from "@views/pages/society/society";
import FileUploadListPage from "@views/pages/upload/file_upload_list";
import ResponseDetails from "@views/components/file_upload_details/response_details";
import OfficerPage from "@views/pages/officer/officer_list";
import ForcePasswordChange from "@views/pages/force_change_password";
import UserList from "@views/components/user/user_list";
import ThirdLevelValidation from "@views/components/file_upload_details/third_level_validation";
import SecondaryLevelValidation from "@views/components/file_upload_details/secondary_level_validation";
import AdditionalFileUploadListPage from "@views/pages/upload/additional_file_upload_list";
import AdditionalFileUploadResponseDetails from "@views/components/additional_file_upload_details/additional_file_upload_response_details";
import society_tab from "@views/components/societies/society_tab";
import ActionItems from "@views/pages/action_items/action_items";
// import DepartmentFileUploadListPage from "@views/pages/department_excel/department_excel";
// import DepartmentFileUploadDetails from "@views/components/department_excel_upload/department_excel_upload_details";
// import DepartmentFileUploadResponseDetails from "@views/components/department_excel_upload/department_excel_file_upload_list";
// import JewelFileUploadResponseDetails from "@views/components/jewel_excel_upload/jewel_excel_file_upload_list";
// import JewelFileUploadListPage from "@views/pages/jewel_excel/jewel_excel";
// import DepartmentExcel from "@views/pages/department_excel/department_excel";
import TicketRaising from "@views/pages/ticket_raising/ticket_raising";

export const ROUTES = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  USER: "/user",
  SOCIETY_PAGE: "/society",
  FILE_UPLOAD_DETAILS: "/file-upload-details",
  RESPONSE_DETAILS: "/response-details",
  FORCE_PASSWORD_CHANGE: "/password-change",
  THIRD_RESPONSE_DETAILS: "/third-level-response-details",
  SECONDARY_LEVEL_VALIDATION: "/secondary-level-validation",
  ADDITIONAL_FILE_UPLOAD: "/additional-file-upload",
  ADDITIONAL_FILE_UPLOAD_RESPONSE: "/additional-file-upload-response",
  ACTION_ITEMS: "/action-items",
  DEPARTMENT_EXCEL: "/department-excel-logs",
  TICKETS: "/tickets",
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    key: "login",
    component: Login,
    authenticate: false,
  },
  {
    path: ROUTES.FORCE_PASSWORD_CHANGE,
    exact: true,
    key: "password-change",
    component: ForcePasswordChange,
    authenticate: false,
  },
  {
    key: "officer-login",
    component: AdminLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.DASHBOARD,
        exact: true,
        key: "dashboard",
        component: Dashboard,
        authenticate: false,
      },

      {
        path: ROUTES.SOCIETY_PAGE,
        exact: true,
        key: "society_page",
        component: society_tab,
        authenticate: false,
      },
      {
        path: ROUTES.ACTION_ITEMS,
        exact: true,
        key: "action_items",
        component: ActionItems,
        authenticate: false,
      },
      {
        path: `${ROUTES.FILE_UPLOAD_DETAILS}/:id?`,
        exact: true,
        key: "file_upload_details",
        component: FileUploadListPage,
        authenticate: false,
      },
      {
        path: `${ROUTES.RESPONSE_DETAILS}/:id?/:society_id?`,
        exact: true,
        key: "response_details",
        component: ResponseDetails,
        authenticate: false,
      },
      {
        path: `${ROUTES.OFFICER_PAGE}`,
        exact: true,
        key: "officer_details",
        component: OfficerPage,
        authenticate: false,
      },
      {
        path: `${ROUTES.USER}`,
        exact: true,
        key: "user_list",
        component: UserList,
        authenticate: false,
      },
      {
        path: `${ROUTES.THIRD_RESPONSE_DETAILS}/:id?/:society_id?`,
        exact: true,
        key: "third-response_details",
        component: ThirdLevelValidation,
        authenticate: false,
      },
      {
        path: `${ROUTES.SECONDARY_LEVEL_VALIDATION}/:id?/:society_id?`,
        exact: true,
        key: "secondary-level-validation",
        component: SecondaryLevelValidation,
        authenticate: false,
      },
      {
        path: `${ROUTES.ADDITIONAL_FILE_UPLOAD}/:id?`,
        exact: true,
        key: "additional_file_upload_details",
        component: AdditionalFileUploadListPage,
        authenticate: false,
      },
      {
        path: `${ROUTES.ADDITIONAL_FILE_UPLOAD_RESPONSE}/:id?/:society_id?`,
        exact: true,
        key: "additional_file_upload_response_details",
        component: AdditionalFileUploadResponseDetails,
        authenticate: false,
      },
      // {
      //   path: `${ROUTES.DEPARTMENT_FILE_UPLOAD}/:id?`,
      //   exact: true,
      //   key: "department_file_upload_details",
      //   component: DepartmentFileUploadListPage,
      //   authenticate: false,
      // },
      // {
      //   path: `${ROUTES.DEPARTMENT_FILE_UPLOAD_RESPONSE}/:id?/:society_id?`,
      //   exact: true,
      //   key: "department_file_upload_response_details",
      //   component: DepartmentFileUploadResponseDetails,
      //   authenticate: false,
      // },
      // {
      //   path: `${ROUTES.JEWEL_FILE_UPLOAD}/:id?`,
      //   exact: true,
      //   key: "department_file_upload_details",
      //   component: JewelFileUploadListPage,
      //   authenticate: false,
      // },
      // {
      //   path: `${ROUTES.JEWEL_FILE_UPLOAD_RESPONSE}/:id?/:society_id?`,
      //   exact: true,
      //   key: "department_file_upload_response_details",
      //   component: JewelFileUploadResponseDetails,
      // },
      // {
      //   path: `${ROUTES.DEPARTMENT_EXCEL}/:id?/:society_id?`,
      //   exact: true,
      //   key: "Department Excel",
      //   component: DepartmentExcel,
      //   authenticate: false,
      // },
      {
        path: `${ROUTES.TICKETS}`,
        exact: true,
        key: "tickets",
        component: TicketRaising,
        authenticate: false,
      },
    ],
  },

  {
    path: ROUTES.LOGIN,
    exact: true,
    key: "login",
    component: Login,
    authenticate: false,
  },
];

export default myRoutes;
