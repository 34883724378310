import { Text } from "native-base";

export const ProjectName = "ECS Data Migration";

export const amount_format = (value) => {
  return value.toLocaleString("en-IN");
};

export const amountFormat = (value) => {
  if (typeof value === "number")
    return (
      <Text textAlign="right">{`${value
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
        .replace("₹", "")}`}</Text>
    );
  else return <Text textAlign="right">{"0"}</Text>;
};

export const amountFormatWithSymbol = (value) => {
  if (typeof value === "number")
    return (
      <Text textAlign="right">{`${value.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })}`}</Text>
    );
  else return <Text textAlign="right">{"0"}</Text>;
};

export const amountFormatWithSymbolAndColor = (value) => {
  if (typeof value === "number")
    return (
      <Text color={"blue.600"}>{`${value.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })}`}</Text>
    );
  else return <Text>{"0"}</Text>;
};

export const issue_types = [
  { id: "data_error", value: "Data Error" },
  { id: "data_missing", value: "Data Missing" },
  { id: "new_feature_request", value: "New Feature Request" },
  { id: "request_test_credential", value: "Request Test credential" },
  { id: "request_live_credential", value: "Request Live credential" },
];

export const received_through_options = [
  { id: "call", value: "Call" },
  { id: "mail", value: "Mail" },
  { id: "migration_portal", value: "Meeting" },
  { id: "whatsapp", value: "WhatsApp" },
];

export const status_options = [
  { id: "awaiting_rcs_approval", value: "Awaiting RCS Approval" },
  { id: "data_issue", value: "Data Issue" },
  { id: "invalid", value: "Invalid" },
  { id: "open", value: "Open" },
  { id: "pending", value: "Pending" },
  { id: "resolved", value: "Resolved" },
  { id: "rcs_approved", value: "RCS Approved" },
];

export const type_warnings = {
  data_error: "Please check the filled excel sheet data before proceeding.",
  data_missing: "Ensure all required fields are filled in before applying.",
  new_feature_request: "Provide details about the new feature you'd like to request.",
  request_test_credential: "Submit your request for test credentials to proceed.",
  request_live_credential: "Submit your request for live credentials.",
};

export const issue_subcategory = [
  { id: "emi", value: "EMI" },
  { id: "sb_account", value: "SB Account" },
  { id: "others", value: "Others" },
]

export const feature_descriptions = {
  sb_account:"Feature Description sb account",
  emi:"Feature Description emi account"
}
