import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, Pressable, Modal } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Select, Table, Button, Form, Space } from "antd";
import { showToast } from "@helpers/toast";
import AntdModal from "@views/components/ui/antd_modal";
import { useHistory, useLocation } from "react-router-dom";
import FileUploadDetails from "./file_upload_details";
import {
  dynamicClear,
  dynamicRequest,
  get_one_society_query,
  get_user_excel_progress_query,
  get_user_latest_excel_query,
  useDynamicSelector,
  user_excel_list_query,
} from "@services/redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import Reports from "../societies/reports";
import { MdBugReport } from "react-icons/md";
import { retrieveItem } from "@helpers/storage";
import DownloadExcelToPDF from "../societies/download_excel_to_pdf";
import FileDownloadDetails from "./file_download_details";
import FileUploadModalStatus from "./file_upload_modal_status";
import ChangePasswordModal from "./change_password_modal";

const FileUploadDetailsList = (props) => {
  const location = useLocation();
  const Society = location.state && location.state[0];
  let role = retrieveItem("role");

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  const [model_open, setModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [action_item, setActionItem] = useState({});
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [is_gl_mismatch, set_is_gl_mismatch] = useState(false);
  const [change_password_modal, show_change_password_modal] = useState(false);

  const [report_modal_open, setReportModalOpen] = useState(false);
  const [download_modal_open, setDownloadModalOpen] = useState(false);
  const { items, loading, pagination } = useDynamicSelector("user_excel_list");
  const { name, username } = useDynamicSelector("getSociety");

  const {
    id: latest_upload_id,
    excel_url,
    loading: latest_upload_download_loading,
  } = useDynamicSelector("getUserLatestExcel");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    if (page) {
      setCurrent(parseInt(page, 10));
    }
    getUserExcels(current, limit, status);
  }, [location.search]);

  useEffect(() => {
    getSociety();
  }, [id]);

  useEffect(() => {}, [latest_upload_id]);

  useEffect(() => {
    if (latest_upload_id) {
      window.open(excel_url);
    } else if (latest_upload_id === null) {
      setDownloadModalOpen(true);
    }
    dispatch(dynamicClear("getUserLatestExcel"));
  }, [latest_upload_id]);

  const handleReport = (item, gl_mismatch) => {
    console.log("gl_mismatch: ", gl_mismatch);
    if (gl_mismatch === "gl_mismatch") {
      console.log("gl_mismatch: ", gl_mismatch);
      set_is_gl_mismatch(true);
      setReportModalOpen(true)
    } else {
      set_is_gl_mismatch(false);
      setActionItem(item);
      setReportModalOpen(true);
    }
  };

  const handleReportClose = () => {
    setReportModalOpen(false);
  };

  const handle_download_modal_open = () => {
    getUserLatestExcelList();
  };

  const handleDownloadModalClose = () => {
    setDownloadModalOpen(false);
    dispatch(dynamicClear("getUserLatestExcel"));
  };

  const getUserExcels = (page_number, page_limit, status) => {
    let user_excel_list_key = [{ key: "user_excel_list", loading: true }];
    let user_excel_list_variables = {
      page_number,
      page_limit,
      status,
      user_id: id,
    };
    dispatch(
      dynamicRequest(
        user_excel_list_key,
        user_excel_list_query,
        user_excel_list_variables
      )
    );
  };
  const getSociety = () => {
    let society_key = [{ key: "getSociety", loading: true }];
    let society_variables = {
      id: id,
    };
    dispatch(
      dynamicRequest(society_key, get_one_society_query, society_variables)
    );
  };

  const getUserLatestExcelList = () => {
    let user_latest_excel_key = [{ key: "getUserLatestExcel", loading: true }];
    dispatch(
      dynamicRequest(user_latest_excel_key, get_user_latest_excel_query)
    );
  };

  const fetchData = (page_number, page_limit) => {
    getUserExcels(page_number, page_limit, status);
    setCurrent(page_number);
    setLimit(page_limit);
    history.push(`${location.pathname}?page=${page_number}`);
  };

  const handleAdd = () => {
    setModalOpen(true);
    form.resetFields();
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleBack = () => {
    history.goBack();
  };

  const renderStatus = (record) => {
    if (
      record?.is_sheet_processing ||
      record?.is_column_processing ||
      record?.is_data_processing
    )
      return "Processing";
    else if (
      record?.is_sheet_valid &&
      record?.is_column_valid &&
      record?.is_valid &&
      record?.is_second_level_validated &&
      record?.secondary_level_validated &&
      role === "Society"
    )
      return (
        <Pressable
          onPress={() => {
            handleReport(record);
          }}
        >
          <Text color={"blue.600"}>{"Valid File"}</Text>
        </Pressable>
      );
    else if (
      // (record?.is_sheet_valid &&
      //   record?.is_column_valid &&
      //   record?.is_valid &&
      //   record?.total_match_count !== record?.total_user_excel_match_count &&
      //   // record?.secondary_level_validated &&
      //   // !record?.third_level_validated &&
      //   // role === "Society"
      //   role === "Admin") ||
      // role === "rcs" ||
      // role === "mdccb" ||
      // role === "Zone Admin"
      record?.is_sheet_valid &&
      record?.is_column_valid &&
      record?.is_valid &&
      record?.total_match_count !==
          record?.total_user_excel_match_count
    )
      return (
        <Pressable
          onPress={() => {
            console.log("gl_mismatch clicked")
            handleReport(record);
            // handleReport(record, "gl_mismatch");
          }}
        >
          <Text color={"blue.600"}>{"GL mismatch"}</Text>
        </Pressable>
      );
    // else if (
    //   record?.is_sheet_valid &&
    //   record?.is_column_valid &&
    //   record?.is_valid &&
    //   record?.total_match_count !== record?.total_user_excel_match_count &&
    //   (role === "Admin" ||
    //     role === "rcs" ||
    //     role === "mdccb" ||
    //     role === "Zone Admin")
    // )
    //   return "GL Mismatch";
    else if (
      record?.is_sheet_valid &&
      record?.is_column_valid &&
      record?.is_valid &&
      record?.secondary_level_validated &&
      // record?.is_third_level_validated &&
      record?.is_second_level_validated &&
      (role === "Admin" ||
        role === "rcs" ||
        role === "mdccb" ||
        role === "Zone Admin")
    )
      return "Valid File";
    else if (
      record?.is_sheet_valid &&
      record?.is_column_valid &&
      !record?.is_valid
    )
      return "Data Error";
    else if (
      record?.is_sheet_valid &&
      !record?.is_column_valid &&
      !record?.is_valid
    )
      return "Column Error";
    else if (
      !record?.is_sheet_valid &&
      !record?.is_column_valid &&
      !record?.is_valid
    )
      return "Sheet Error";
    else if (
      (record?.is_sheet_valid &&
        record?.is_column_valid &&
        record?.is_valid &&
        !record?.secondary_level_validated &&
        role === "Society") ||
      role === "Admin"
    )
      return (
        <Pressable
          onPress={() => {
            handleReport(record);
          }}
        >
          <Text color={"blue.600"}>{"Additional Data Error"}</Text>
        </Pressable>
      );
    else return "Processing";
  };

  const columns = [
    {
      title: t("table:file"),
      dataIndex: "excel_url",
      key: "excel_url",
      sortable: false,
      align: "left",
      width: "30%",
      render: (record) => (
        <span
          onClick={() => window.open(record)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          File
        </span>
      ),
    },
    {
      title: t("table:uploaded_date_time"),
      dataIndex: "uploaded_time",
      key: "uploaded_time",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) =>
        moment(new Date(Number(record)))
          .utcOffset("+05:30")
          .format("DD/MM/YY hh:mm a"),
    },
    {
      title: t("table:response_file"),
      dataIndex: "",
      key: "response_file",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => (
        <span
          onClick={() =>
            history.push(`/response-details/${record.id}/${id}?page=${current}`)
          }
          style={{ color: "blue", cursor: "pointer" }}
        >
          Response
        </span>
      ),
    },
    {
      title: t("table:prelive_validation"),
      dataIndex: "",
      key: "prelive_validation",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => (
        // record?.is_second_level_validated && (
        <span
          onClick={() =>
            history.push(
              `/third-level-response-details/${record.id}/${id}?page=${current}`
            )
          }
          style={{ color: "blue", cursor: "pointer" }}
        >
          Response
        </span>
      ),
    },
    {
      title: t("table:additional_data_validation"),
      dataIndex: "",
      key: "additional_data_validation",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => (
        // !record?.secondary_level_validated && (
        <span
          onClick={() =>
            history.push(
              `/secondary-level-validation/${record.id}/${id}?page=${current}`
            )
          }
          style={{ color: "blue", cursor: "pointer" }}
        >
          Response
        </span>
      ),
    },
    {
      title: t("table:status"),
      dataIndex: "",
      key: "status",
      sortable: false,
      align: "left",
      render: (record) => renderStatus(record),
    },
  ];

  const status_list = [
    {
      name: "Processing",
      value: "processing",
    },
    {
      name: "Invalid File",
      value: "invalid",
    },
    {
      name: "Valid File",
      value: "valid",
    },
  ];

  const {
    status: user_excel_process_status,
    error: user_excel_process_error,
    is_completed,
  } = useDynamicSelector("getUserExcelProgress");

  // useEffect(() => {
  //   if (is_completed) {
  //     set_is_modal_open(false);
  //   } else {
  //     set_is_modal_open(true);
  //   }
  // }, [is_completed]);

  const handle_change_password = ()=>{
    show_change_password_modal(true)
  }
  const handlePasswordModalClose = ()=>{
    show_change_password_modal(false)
  }

  return (
    <>
      {role !== "Society" && (
        <Box top="10px" zIndex="2" mb="10px">
          <Text fontSize="md" fontWeight="bold">
            {name} - {username}
          </Text>
        </Box>
      )}
      <Box top="10px" zIndex="2" mb="10px">
        <Text fontSize="md" fontWeight="bold">
          {t("table:file_upload_details")}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <Box width="300px">
            <Text bold mb="2px">
              Status
            </Text>
            <Select
              style={{ width: "100%" }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              onChange={(e) => {
                setStatus(e);
                getUserExcels(current, limit, e);
              }}
            >
              {status_list?.map((item) => {
                return <Option value={item.value}>{item.name}</Option>;
              })}
            </Select>
          </Box>
          <HStack justifyContent="flex-end" space={"5"}>
            {role !== "Society" && (
              <Space>
                 <Button color="primary"  type="primary" onClick={handle_change_password}>Change Password</Button>
              <Button type="default" danger onClick={handleBack}>
                Back
              </Button>
              </Space>
            )}
          </HStack>
          {id ? (
            ""
          ) : (
            <HStack space={3} justifyContent="flex-end">
              <Box key={`header-action`}>
                <Button
                  style={{
                    height: "45px",
                    color: "white",
                    backgroundColor: "#1799C7",
                  }}
                  onClick={handle_download_modal_open}
                  // onClick={fetchDataFromExcel}
                  color="#158d94"
                  background={"#158d94"}
                  loading={latest_upload_download_loading}
                >
                  Download 2023 File
                </Button>
              </Box>
              <Box key={`header-action`}>
                <Button
                  style={{
                    height: "45px",
                  }}
                  onClick={() => handleAdd()}
                  // onClick={fetchDataFromExcel}
                  color="#158d94"
                  background={"#158d94"}
                >
                  Upload
                </Button>
              </Box>
              <Box key={`header-action`}>
                <Button
                  style={{
                    height: "45px",
                  }}
                  onClick={() => {
                    window.open(
                      "https://v-ecs-migration.blr1.vultrobjects.com/97fd4520-fa77-4297-9b24-01c6f0fe2a89.xlsx"
                    );
                  }}
                  // onClick={handleAdd}
                  color="#158d94"
                  background={"#158d94"}
                >
                  Sample Excel
                </Button>
              </Box>
              {items?.length > 0 &&
                items?.[0]?.is_sheet_valid &&
                items?.[0]?.is_column_valid &&
                items?.[0]?.is_valid &&
                items?.[0]?.secondary_level_validated && (
                  <DownloadExcelToPDF url={items?.[0]?.excel_url} name={name} />
                )}
            </HStack>
          )}
          <Wrapper>
            <Table
              loading={loading}
              columns={columns}
              dataSource={items}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={report_modal_open  }
        onClose={handleReportClose}
        width={1000}
        footer={null}
        header={t("Reports")}
        component={
          <Reports
            close={handleReportClose}
            initialValues={action_item}
            is_society={true}
            is_gl_mismatch={is_gl_mismatch}
          />
        }
      />
      <AntdModal
        isOpen={model_open}
        onClose={handleModalClose}
        width={450}
        header={"File Upload"}
        component={
          <FileUploadDetails
            label={t("add")}
            close={handleModalClose}
            form={form}
            getUserExcels={getUserExcels}
            set_is_modal_open={set_is_modal_open}
          />
        }
      />

      <AntdModal
        isOpen={download_modal_open}
        onClose={handleDownloadModalClose}
        width={450}
        header={"Download 2023 File"}
        component={
          <FileDownloadDetails setDownloadModalOpen={setDownloadModalOpen} />
        }
      />

      <AntdModal
        isOpen={change_password_modal}
        onClose={handlePasswordModalClose}
        width={450}
        header={"Change Password"}
        component={
          <ChangePasswordModal set_open={show_change_password_modal} user_id={id}/>
        }
      />

      <FileUploadModalStatus
        is_modal_open={is_modal_open}
        // handle_modal_clos={handle_modal_clos}
      />
    </>
  );
};
export default FileUploadDetailsList;
