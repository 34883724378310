import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Pagination,
  DatePicker,
  Alert,
  List,
} from "antd";
import { capitalize, upperCase } from "lodash";
import { retrieveItem } from "@helpers/storage";
import VForm from "@views/components/ui/antd_form";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  getDistrictsQuery,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import {
  issue_subcategory,
  issue_types,
  received_through_options,
  status_options,
  type_warnings,
} from "@helpers/constants";
import {
  get_society_list,
  get_society_ticket_list,
  mutation_create_society_ticket,
  mutation_update_society_ticket,
  new_feature_request_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_ticket_raising";
import { showNotification } from "@helpers/notify";
import moment from "moment";
import SearchBox from "../ui/search_box";
import { Box, HStack, Text } from "native-base";
import Attachments from "./attachments";

const TicketRaisingPage = () => {
  const [form_add] = Form.useForm();
  const [form_edit] = Form.useForm();

  let role_type = retrieveItem("role");

  const dispatch = useDispatch();
  const [sort_order, set_sort_order] = useState({ field: null, order: null });
  const [is_add_modal_open, set_is_add_modal_open] = useState(false);
  const [filtered_societies, set_filtered_societies] = useState(null);
  const [societies, set_societies] = useState(null);
  const [desc_id, set_desc_id] = useState(null);
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [society_name_value, set_name_value] = useState(null);
  const [is_edit_modal_open, set_is_edit_modal_open] = useState(false);
  const [search_string, set_search_string] = useState("");
  const [status, set_status] = useState(null);
  const [district_id, set_district_id] = useState(null);
  const [from_date, set_from_date] = useState(null);
  const [to_date, set_to_date] = useState(null);
  const [type_of_ticket, set_type_of_ticket] = useState(null);

  const handle_table_change = (pagination, filters, sorter) => {
    set_sort_order({ field: sorter.field, order: sorter.order });
  };

  const type_of_ticket_now = Form.useWatch("type_of_ticket", form_add);
  const feature_type_now = Form.useWatch("feature_type", form_add);
  const description_now = Form.useWatch("description", form_add);
  console.log("description_now: ", description_now);
  const feature_description_now = Form.useWatch(
    "feature_description",
    form_add
  );

  useEffect(() => {
    set_type_of_ticket(type_of_ticket_now);
  }, [type_of_ticket_now]);

  const [selected_ticket, set_selected_ticket] = useState(null);
  console.log("selected_ticket: ", selected_ticket);
  const [selected_values, set_selected_values] = useState(null);

  useEffect(() => {
    form_edit.setFieldValue("raised_for", selected_ticket?.raised_for);
    form_edit.setFieldValue(
      "feature_type",
      selected_ticket?.features
        ?.map((feature) => feature.feature_name)
        .join(",  ") || ""
    );
    if (selected_ticket?.features?.length === 1) {
      form_edit.setFieldValue(
        "feature_description",
        selected_ticket?.features?.[0]?.feature_description
      );
    } else if (selected_ticket?.features?.length > 1) {
      form_edit.setFieldValue(
        "feature_description",
        "This feature allows users to manage EMI-related configurations and handle SB account operations, ensuring seamless integration with payment systems and access to account management tools."
      );
    }
  }, [selected_ticket]);

  useEffect(() => {
    let desc = null;

    if (feature_type_now?.includes("others")) {
      form_add.setFieldsValue({
        feature_type: "others",
        feature_description: null,
      });
      return;
    }

    if (feature_type_now?.length === 1) {
      feature_descriptions?.forEach((item) => {
        if (
          item?.feature_name == "SB Account" &&
          feature_type_now.includes("sb_account")
        ) {
          desc = item?.feature_description;
          set_desc_id(item?.id);
        } else if (
          item?.feature_name === "EMI" &&
          feature_type_now.includes("emi")
        ) {
          desc = item?.feature_description;
          set_desc_id(item?.id);
        }
      });
    } else if (feature_type_now?.length === 2) {
      set_desc_id(() => {
        return feature_descriptions?.map((desc) => desc?.id);
      });
      desc =
        "This feature allows users to manage EMI-related configurations and handle SB account operations, ensuring seamless integration with payment systems and access to account management tools.";
    } else {
      set_desc_id([]);
    }

    form_add.setFieldsValue({
      feature_description: desc,
    });
  }, [feature_type_now]);

  useEffect(() => {
    if (selected_ticket) {
      const formValues = {
        ...selected_ticket,
        raised_for: selected_ticket.raised_for
          .map((user) => user.name)
          .join(", "),
      };
      form_edit.setFieldsValue(formValues);
    }
  }, [selected_ticket]);

  const { items: society_list, loading: society_list_loading } =
    useDynamicSelector("getSystemUserList");

  const table_items = useDynamicSelector("getSocietyTickets");
  const { items: feature_descriptions } = useDynamicSelector(
    "getNewFeatureRequestList"
  );

  const { items: district_list } = useDynamicSelector("get_districts");

  const {
    status: add_ticket_status,
    error: add_ticket_error,
    loading: add_ticket_loading,
  } = useDynamicSelector("createSocietyTicket");

  const {
    status: update_ticket_status,
    error: update_ticket_error,
    loading: update_ticket_loading,
  } = useDynamicSelector("updateSocietyTicket");

  const { t } = useTranslation();

  const columns = [
    {
      title: "Ticket Code",
      dataIndex: "ticket_number",
      key: "ticket_code",
      render: (data, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p
              onClick={() => {
                set_selected_ticket(record);
                set_is_edit_modal_open(true);
              }}
              style={{
                fontSize: "15px",
                cursor: "pointer",
                color: "dodgerBlue",
              }}
            >
              {data}
            </p>
          </div>
        );
      },
    },
    {
      title: "Society Code",
      dataIndex: "raised_for",
      key: "raised_for",
      hidden: role_type === "Society",
      render: (raised_for) => {
        return Array.isArray(raised_for)
          ? raised_for.map((item) => upperCase(item.username)).join(", ")
          : "-";
      },
    },
    {
      dataIndex: "features.feature_name",
      key: "feature_name",
      hidden: true,
    },
    {
      dataIndex: "features.feature_description",
      key: "feature_description",
      hidden: true,
    },
    {
      title: "Raised Date",
      dataIndex: "raised_on",
      key: "raised_on",
      width: "110px",
      sorter: true,
      render: (date) => {
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Type of Ticket",
      dataIndex: "type_of_ticket",
      key: "type_of_ticket",
      render: (type_of_ticket) => {
        const issue = issue_types.find((item) => item.id === type_of_ticket);
        return issue ? issue.value : "Unknown Issue";
      },
    },
    {
      title: "",
      hidden: true,
      dataIndex: "district.name",
      key: "district",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "160px",
    },
    {
      title: "Issue Raised Through",
      dataIndex: "issue_raised_through",
      key: "issue_raised_through",
      render: (issue_raised_through) => {
        const option = received_through_options.find(
          (item) => item.id === issue_raised_through
        );
        return option ? option.value : "Unknown Source";
      },
    },
    {
      title: "Resolved Date",
      dataIndex: "resolved_on",
      key: "resolved_on",
      sorter: true,
      render: (date) => (date ? moment(date).format("DD/MM/YYYY") : "-"),
    },
    {
      title: "Status",
      dataIndex: "ticket_status",
      key: "status",
      render: (ticket_status) => {
        const option = status_options.find((item) => item.id === ticket_status);
        return option ? option.value : "Unknown Status";
      },
    },
  ].filter((column) => !column.hidden);

  useEffect(() => {
    getTickets();
    get_feature_descriptions();
    if (!society_list?.length > 0) {
      get_societies();
    }
    if (!district_list?.length > 0) {
      get_district();
    }
  }, [
    search_string,
    search_string,
    status,
    district_id,
    from_date,
    to_date,
    sort_order,
  ]);

  useEffect(() => {
    if (add_ticket_status === "Success") {
      showNotification({
        type: "success",
        message: "Ticket Created Successfully",
      });
      set_is_add_modal_open(false);
      getTickets();
      dispatch(dynamicClear("createSocietyTicket"));
      form_add.resetFields();
    } else if (add_ticket_error) {
      showNotification({ type: "error", message: add_ticket_error?.message });
      dispatch(dynamicClear("getTicketsList"));
    } else if (update_ticket_status === "Success") {
      showNotification({
        type: "success",
        message: "Ticket Updated Successfully",
      });
      set_is_edit_modal_open(false);
      form_edit.resetFields();
      getTickets();
      dispatch(dynamicClear("updateSocietyTicket"));
      form_edit.resetFields();
    } else if (update_ticket_error) {
      showNotification({
        type: "error",
        message: update_ticket_error?.message,
      });
      dispatch(dynamicClear("getTicketsList"));
    }
  }, [
    add_ticket_status,
    add_ticket_error,
    update_ticket_status,
    update_ticket_error,
  ]);

  const get_societies = () => {
    let society_key = [{ key: "getSystemUserList", loading: true }];
    let society_variables = {};
    dispatch(dynamicRequest(society_key, get_society_list, society_variables));
  };

  const get_district = () => {
    let keys = [{ key: "get_districts", loading: true }];
    dispatch(dynamicRequest(keys, getDistrictsQuery));
  };
  const get_feature_descriptions = () => {
    let keys = [{ key: "getNewFeatureRequestList", loading: true }];
    dispatch(dynamicRequest(keys, new_feature_request_query));
  };

  const getTickets = (page_number, page_limit) => {
    let society_key = [{ key: "getSocietyTickets", loading: true }];
    let society_variables = {
      page_number,
      page_limit,
      search_string,
      ticket_status: status,
      district_id,
      raised_date_from: from_date,
      raised_date_to: to_date,
      sort_order: sort_order.order === "ascend" ? "asc" : "desc",
      sort_by: sort_order.field,
    };
    dispatch(
      dynamicRequest(society_key, get_society_ticket_list, society_variables)
    );
    set_page_number(page_number);
    set_page_limit(page_limit);
  };

  const add_ticket = (values) => {
    const { screenshot_url, feature_type, feature_description,description, ...rest } =
      values;

    let url;
    url = values.screenshot_url?.map((item) => item.url);
    let society_key = [{ key: "createSocietyTicket", loading: true }];
    let society_variables = {
      data: {
        ...rest,
        description:feature_type === "others" ? feature_description: description,
        screenshot_url: url,
        new_feature: feature_type?.includes("others")
          ? null
          : Array.isArray(desc_id)
          ? desc_id
          : [desc_id],
      },
    };

    dispatch(
      dynamicRequest(
        society_key,
        mutation_create_society_ticket,
        society_variables
      )
    );
  };

  const update_ticket = (values) => {
    let society_key = [{ key: "updateSocietyTicket", loading: true }];
    let society_variables = {
      ticket_id: selected_ticket?.id,
      ticket_status: values?.ticket_status,
      internal_description: values?.internal_description,
    };
    dispatch(
      dynamicRequest(
        society_key,
        mutation_update_society_ticket,
        society_variables
      )
    );
  };

  const handle_add_submit = (values) => {
    add_ticket(values);
  };

  const handle_edit_submit = (values) => {
    update_ticket(values);
  };

  useEffect(() => {
    const filtered_societies = society_list
      ?.filter(
        (society) =>
          typeof society?.name === "string" &&
          society.name
            .toLowerCase()
            .includes(society_name_value?.toLowerCase() || "")
      )
      .reduce((unique, society) => {
        if (!unique.some((h) => h.name === society.name)) {
          unique.push(society);
        }
        return unique;
      }, []);

    set_societies(filtered_societies || []);
  }, [society_name_value]);

  const onchange_society_names = useCallback(
    (string) => {
      if (!string) {
        set_filtered_societies(society_list || []);
        return;
      }

      const searchTerm = string.toLowerCase();

      const filtered_societies = society_list?.filter((society) => {
        const usernameMatches =
          typeof society?.username === "string" &&
          society.username.toLowerCase().includes(searchTerm.toLowerCase());
        const nameMatches =
          typeof society?.name === "string" &&
          society.name.toLowerCase().includes(searchTerm.toLowerCase());

        return usernameMatches || nameMatches;
      });

      const sorted_societies = filtered_societies.sort((a, b) => {
        const isExactA = a.username.toLowerCase() === searchTerm ? -1 : 0;
        const isExactB = b.username.toLowerCase() === searchTerm ? -1 : 0;
        return isExactB - isExactA || a.username.localeCompare(b.username);
      });

      set_filtered_societies(sorted_societies || []);
    },
    [society_list]
  );

  const handle_values_change = (values) => {};

  return (
    <>
      <div>
        <h3>Tickets</h3>

        <HStack space="5" mt={5} mb={5}>
          <Box width="250px">
            <Text bold>Status</Text>
            <Select
              style={{ width: "100%" }}
              allowClear
              showSearch
              value={status}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              onChange={(e) => {
                set_status(e);
              }}
            >
              {status_options?.map((item) => {
                return <Option value={item.id}>{item.value}</Option>;
              })}
            </Select>
          </Box>
          {role_type !== "Society" && (
            <Box width="250px">
              <Text bold>District</Text>
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                value={district_id}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  set_district_id(value);
                }}
              >
                {district_list?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Box>
          )}
          <Box width="180px">
            <Text bold>From Date</Text>

            <DatePicker
              onChange={(date) => {
                set_from_date(date?.toISOString());
              }}
            />
          </Box>
          <Box width="180px">
            <Text bold>To Date</Text>

            <DatePicker
              onChange={(date) => {
                set_to_date(date?.toISOString());
              }}
            />
          </Box>
        </HStack>

        <HStack
          space={3}
          justifyContent="flex-end"
          className="tickets-search-section"
        >
          <SearchBox
            label={t("table:Search")}
            width={{
              base: "300px",
              xs: "200px",
              sm: "200px",
              md: "300px",
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                set_search_string(event.target.value);
              }
            }}
          />
          <Box key={`header-action`} alignSelf={"center"}>
            <Button
              onClick={() => set_is_add_modal_open(true)}
              color="#158d94"
              style={{
                height: "50px",
              }}
            >
              {t("Add")}
            </Button>
          </Box>
        </HStack>

        <Table
          columns={columns}
          dataSource={table_items?.items}
          loading={table_items?.loading}
          pagination={false}
          onChange={handle_table_change}
        />
        <div className="ticket-raising-pagination">
          <Pagination
            total={table_items?.pagination?.total_count || 0}
            showSizeChanger={true}
            current={page_number || 1}
            hideOnSinglePage={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={(page, pageSize) => getTickets(page, pageSize)}
          />
        </div>
      </div>

      <Modal
        title="Add Ticket"
        open={is_add_modal_open}
        onCancel={() => {
          form_add.resetFields();
          set_is_add_modal_open(false);
        }}
        footer={null}
        width={930}
      >
        <Form
          form={form_add}
          onFinish={handle_add_submit}
          onValuesChange={handle_values_change}
          style={{ gap: "10px" }}
        >
          {role_type !== "Society" && (
            <Form.Item
              name="raised_for"
              label="Society Code"
              rules={[{ required: true, message: "Please select a Society!" }]}
            >
              <Select
                mode="multiple"
                size="large"
                showSearch
                allowClear
                placeholder="Type atleast three letters to search"
                optionFilterProp="children"
                defaultActiveFirstOption={true}
                popupMatchSelectWidth={false}
                onChange={(data, record) => {
                  set_name_value(record?.children);
                }}
                onSearch={onchange_society_names}
                filterOption={(input, option) => {
                  return option?.children
                    ?.toLowerCase()
                    .includes(input.toLowerCase());
                }}
                dropdownStyle={{ maxWidth: "700px" }}
                // onDropdownVisibleChange={on_dropdown_visible_change}
              >
                {filtered_societies
                  ? filtered_societies
                      .slice(0, 30)
                      ?.sort((a, b) => a.username.localeCompare(b.username))
                      .map((society) => (
                        <Option key={society.id} value={`${society?.id}`}>
                          {`${society?.username} - ${society?.name}`}
                        </Option>
                      ))
                  : society_list
                      ?.slice(0, 30)
                      ?.sort((a, b) => a.username.localeCompare(b.username))
                      .map((society) => (
                        <Option key={society.id} value={`${society?.id}`}>
                          {`${society?.username} - ${society?.name}`}
                        </Option>
                      ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="type_of_ticket"
            label="Type of Ticket"
            rules={[
              { required: true, message: "Please select an issue type!" },
            ]}
          >
            <Select>
              {issue_types?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {capitalize(option.value)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {type_of_ticket_now && (
            <div className="issue-info-warning">
              <Alert
                // message="Additional Information"
                description={type_warnings[type_of_ticket]}
                type="warning"
                showIcon
                style={{ fontSize: "13px", padding: "8px" }}
              />
            </div>
          )}

          {type_of_ticket_now === "new_feature_request" && (
            <Form.Item
              name="feature_type"
              label="Feature Type"
              rules={[
                { required: true, message: "Please select a feature type!" },
              ]}
            >
              <Select allowClear mode="multiple">
                {issue_subcategory.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {feature_type_now && (
            <Form.Item
              name="feature_description"
              label=" Feature Description"
              rules={[
                {
                  required: true,
                  message: "Please add a feature description!",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: "125px" }}
                disabled={feature_type_now !== "others"}
              />
            </Form.Item>
          )}

{
  feature_type_now !="others" && (
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please add a description!" }]}
          >
            <Input.TextArea />
          </Form.Item>
  )
}

          {role_type !== "Society" && (
            <Form.Item
              name="issue_raised_through"
              label="Issue Raised Through"
              rules={[{ required: true, message: "Please select a method!" }]}
            >
              <Select>
                {received_through_options?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {capitalize(option.value)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <div style={{ textAlign: "right" }}>
            <VForm.File
              label={t("form:file")}
              field="screenshot_url"
              maxCount={10}
              rules={[
                {
                  required: true,
                  message: t("error:upload_file_required"),
                },
              ]}
            />
            <Form.Item>
              <Button
                onClick={() => {
                  set_is_add_modal_open(false);
                  form_add.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "10px" }}
                loading={add_ticket_loading}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title={`Update Ticket - ${selected_ticket?.ticket_number}`}
        open={is_edit_modal_open}
        onCancel={() => {
          set_is_edit_modal_open(false);
          set_selected_ticket(null);
        }}
        footer={null}
        width={930}
      >
        <Form form={form_edit} onFinish={handle_edit_submit}>
          {role_type != "Society" && (
            <div className="attachments">
              <List
                bordered
                header={<b>Society Details</b>}
                dataSource={selected_ticket?.raised_for || []}
                renderItem={(ticket, index) => (
                  <List.Item>
                    {`${index + 1}. ${ticket?.username} - ${ticket?.name} - ${
                      ticket?.district[0].name
                    }`}
                  </List.Item>
                )}
              />
            </div>
          )}
          <div className="attachments">
            <Attachments urls={selected_ticket?.screenshot_url} />
          </div>

          {role_type !== "Society" && (
            <Form.Item
              hidden
              name="raised_for"
              label="Society Code"
              rules={[
                { required: true, message: "Please select a Society Code!" },
              ]}
            >
              <Select
                disabled={is_edit_modal_open}
                loading={society_list_loading}
              >
                {society_list?.map((option) => (
                  <Select.Option key={option.id} value={option.username}>
                    {`${capitalize(option.username)}-${capitalize(
                      option?.name
                    )}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="type_of_ticket"
            label="Type of Ticket"
            rules={[
              { required: true, message: "Please select an issue type!" },
            ]}
          >
            <Select
              disabled={is_edit_modal_open}
              className="custom-disabled-input"
            >
              {issue_types.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {capitalize(option.value)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {selected_ticket?.features?.length > 0 && (
            <Form.Item
              name="feature_type"
              label="Feature Type"
              rules={[
                { required: true, message: "Please select a feature type!" },
              ]}
            >
              <Select allowClear disabled mode="multiple" className="custom-disabled-dropdown">
                {issue_subcategory.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selected_ticket?.features?.length > 0 && (
            <Form.Item
              name="feature_description"
              label=" Feature Description"
              rules={[
                {
                  required: true,
                  message: "Please add a feature description!",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: "80px" }}
                disabled={is_edit_modal_open}
              />
            </Form.Item>
          )}

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please add a description!" }]}
          >
            <Input.TextArea disabled={is_edit_modal_open} />
          </Form.Item>
          <Form.Item hidden name="district" label="district">
            <Input.TextArea />
          </Form.Item>
          {role_type != "Society" && (
            <Form.Item
              name="issue_raised_through"
              label="Issue Received Through"
              rules={[{ required: true, message: "Please select a method!" }]}
            >
              <Select disabled={is_edit_modal_open}>
                {received_through_options.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {capitalize(option.value)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="ticket_status"
            label="Status"
            rules={[{ required: true, message: "Please select a status!" }]}
          >
            <Select disabled={role_type==="Society"}>
              {status_options.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {capitalize(option.value)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {role_type != "Society" && (
            <Form.Item
              name="internal_description"
              label="Resolution"
              rules={[{ required: true, message: "Please add a resolution!" }]}
            >
              <Input.TextArea disabled={false} />
            </Form.Item>
          )}

          <div style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                onClick={() => {
                  set_is_edit_modal_open(false);
                  set_selected_ticket(null);
                }}
              >
                Cancel
              </Button>
              {role_type != "Society" && (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "10px" }}
                  loading={update_ticket_loading}
                >
                  Submit
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TicketRaisingPage;
