import TicketRaisingPage from '@views/components/ticket_raising/ticket_raising_page'
import React from 'react'

const TicketRaising = () => {
  return (
    <div>
        <TicketRaisingPage />
    </div>
  )
}

export default TicketRaising
