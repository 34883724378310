import React from "react";
import { List } from "antd";

const Attachments = ({ urls }) => {
  if (!urls || urls.length === 0) {
    return <p>No attachments available.</p>;
  }

  return (
    <List
      bordered
      header={<b>Attachments</b>}
      dataSource={urls}
      renderItem={(url, index) => (
        <List.Item>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {`View Attachment ${index + 1}`}
          </a>
        </List.Item>
      )}
    />
  );
};

export default Attachments;
