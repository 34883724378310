import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, Pressable, Modal } from "native-base";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Wrapper from "@views/components/ui/wrapper";
import { Pagination, Select, Table, Button, Form } from "antd";
import { showToast } from "@helpers/toast";
import AntdModal from "@views/components/ui/antd_modal";
import { useHistory, useLocation } from "react-router-dom";
import AdditionalFileUploadDetails from "./additional_file_upload_details";
import {
  dynamicRequest,
  get_one_society_query,
  useDynamicSelector,
  get_additional_excel_list_query,
  user_excel_list_query,
} from "@services/redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import Reports from "../societies/reports";
import { MdBugReport } from "react-icons/md";
import { retrieveItem } from "@helpers/storage";
import { startCase, toLower, xor } from "lodash";
// import DepartmentExcelUpload from "../department_excel_upload/department_excel_upload";

const AdditionalFileUploadList = (props) => {
  const location = useLocation();
  const Society = location.state && location.state[0];
  let role = retrieveItem("role");

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  const [model_open, setModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [action_item, setActionItem] = useState({});

  const [report_modal_open, setReportModalOpen] = useState(false);
  const [excelUrl, setExcelUrl] = useState(null);
  const [form] = Form.useForm();
  const {
    items: additional_sheet_list,
    loading: additional_loading,
    pagination,
  } = useDynamicSelector("getAdditionalExcels");

  const { items } = useDynamicSelector("user_excel_list");
  const { name, username } = useDynamicSelector("getSociety");
  const { id } = useParams();
  useEffect(() => {
    getAdditionalUserExcel(current, limit, status);
    getUserExcels();
  }, []);

  useEffect(() => {
    getSociety();
  }, [id]);

  useEffect(() => {
    setExcelUrl(items?.[0]?.excel_url);
  }, [items]);

  const handleReport = (item) => {
    setActionItem(item);
    setReportModalOpen(true);
  };

  const axios = require("axios");
  const XLSX = require("xlsx");

  async function fetchDataFromExcel() {
    try {
      const response = await axios.get(excelUrl, {
        responseType: "arraybuffer",
      });
      const workbook = XLSX.read(response.data, { type: "array" });
      const sheetIndex = 5;
      const sheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      const activeMembers = jsonData?.filter(
        (x) =>
          x["Member Status"]?.toLowerCase() === "active" ||
          x["Member Status"]?.toLowerCase() === "dormant"
      );

      const memberNumbers = activeMembers.map(
        (obj) => obj[Object.keys(obj)[1]]
      );
      const DepartmentNames = Array.from(
        new Set(activeMembers.map((obj) => obj["Department"]))
      );
      const subDepartmentNames = Array.from(
        new Set(activeMembers.map((obj) => obj["Sub Department"]))
      );

      // Create a new workbook
      const newWorkbook = XLSX.utils.book_new();

      // Create sheet 1
      const newSheetData1 = memberNumbers.map((value, index) => ({
        "Member Number": value,
        Amount: null,
        "Interest Payable": null,
      }));

      const newSheet1 = XLSX.utils.json_to_sheet(newSheetData1);

      newSheet1["!cols"] = [
        { width: 5 }, // S.No column width
        { width: 15 }, // Member Number column width
        { width: 10 }, // Amount column width
        { width: 10 }, // Interest column width
      ];

      // // Set header background color in sheet 1
      // const headerRange1 = XLSX.utils.decode_range(newSheet1["!ref"]);
      // for (let col = headerRange1.s.c; col <= headerRange1.e.c; col++) {
      //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      //   newSheet1[cellAddress].s = {
      //     font: { bold: true },
      //     fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFA07A" } },
      //   };
      // }

      // Create sheet 2
      const newSheetData2 = DepartmentNames.map((value, index) => ({
        Name: value,
        Code: null,
      }));

      const newSheet2 = XLSX.utils.json_to_sheet(newSheetData2);
      newSheet2["!cols"] = [
        { width: 5 }, // S.No column width
        { width: 20 }, // Departments column width
        { width: 10 }, // Code column width
      ];

      // Set header background color in sheet 2
      // const headerRange2 = XLSX.utils.decode_range(newSheet2["!ref"]);
      // for (let col = headerRange2.s.c; col <= headerRange2.e.c; col++) {
      //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      //   newSheet2[cellAddress].s = {
      //     font: { bold: true },
      //     fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFA07A" } },
      //   };
      // }

      // Create sheet 3
      const newSheetData3 = subDepartmentNames.map((value, index) => ({
        Name: value,
        Code: null,
      }));

      const newSheet3 = XLSX.utils.json_to_sheet(newSheetData3);

      newSheet3["!cols"] = [
        { width: 5 }, // S.No column width
        { width: 25 }, // Departments column width
        { width: 10 }, // Code column width
      ];
      // Set header background color in sheet 3
      // const headerRange3 = XLSX.utils.decode_range(newSheet3["!ref"]);
      // for (let col = headerRange3.s.c; col <= headerRange3.e.c; col++) {
      //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
      //   newSheet3[cellAddress].s = {
      //     font: { bold: true },
      //     fill: { bgColor: { indexed: 64 }, fgColor: { rgb: "FFA07A" } },
      //   };
      // }

      // Append sheets to the new workbook
      XLSX.utils.book_append_sheet(newWorkbook, newSheet1, "SB Account");
      XLSX.utils.book_append_sheet(newWorkbook, newSheet2, "Department");
      XLSX.utils.book_append_sheet(newWorkbook, newSheet3, "Sub Department");

      // Write the new workbook to a file
      XLSX.writeFile(newWorkbook, "Additional Data.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleReportClose = () => {
    setReportModalOpen(false);
  };

  const getUserExcels = () => {
    let user_excel_list_key = [{ key: "user_excel_list", loading: true }];
    let user_excel_list_variables = {
      page_number: 1,
      page_limit: 10,
      status: "",
      user_id: id,
    };
    dispatch(
      dynamicRequest(
        user_excel_list_key,
        user_excel_list_query,
        user_excel_list_variables
      )
    );
  };
  const getAdditionalUserExcel = (page_number, page_limit, status) => {
    let user_excel_list_key = [{ key: "getAdditionalExcels", loading: true }];
    let user_excel_list_variables = {
      page_number,
      page_limit,
      status,
      user_id: id,
    };
    dispatch(
      dynamicRequest(
        user_excel_list_key,
        get_additional_excel_list_query,
        user_excel_list_variables
      )
    );
  };
  const getSociety = () => {
    let society_key = [{ key: "getSociety", loading: true }];
    let society_variables = {
      id: id,
    };
    dispatch(
      dynamicRequest(society_key, get_one_society_query, society_variables)
    );
  };
  const fetchData = (page_number, page_limit) => {
    getAdditionalUserExcel(page_number, page_limit, status);
    setCurrent(page_number);
    setLimit(page_limit);
  };

  const handleAdd = (type) => {
      setModalOpen(true);
      form.resetFields();
    }
  
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleBack = () => {
    history.goBack();
  };

  let columns = [
    {
      title: t("table:file"),
      dataIndex: "excel_url",
      key: "excel_url",
      sortable: false,
      align: "left",

      width: "30%",
      render: (record) => {
        return (
          <a href={record} download="custom_filename.txt">
            File
          </a>
        );
      },
    },
    {
      title: t("table:uploaded_date_time"),
      dataIndex: "uploaded_time",
      key: "uploaded_time",
      sortable: false,
      align: "left",
      flex: 1,
      render: (record) => {
        return moment(new Date(Number(record)))
          .utcOffset("+05:30")
          .format("DD/MM/YY hh:mm: a");
      },
    },
    {
      title: t("table:response_file"),
      dataIndex: "",
      key: "response_file",
      sortable: false,
      align: "left",
      flex: 1,

      render: (record) => {
        return (
          <a
            href={`/additional-file-upload-response/${record.id}/${id}`}
            _target={"_blank"}
          >
            Response
          </a>
        );
      },
    },

    {
      title: t("table:status"),
      dataIndex: "",
      key: "status",
      sortable: false,
      align: "left",
      render: (_, record) =>
        record?.is_valid
          ? "Valid File"
          : record?.error_type
          ? startCase(toLower(record.error_type).replace(/_/g, " "))
          : null,
    },
  ];

  const status_list = [
    {
      name: "Processing",
      value: "processing",
    },
    {
      name: "Invalid File",
      value: "invalid",
    },
    {
      name: "Valid File",
      value: "valid",
    },
  ];
  return (
    <>
      {role !== "Society" && (
        <Box top="10px" zIndex="2" mb="10px">
          <Text fontSize="md" fontWeight="bold">
            {name} - {username}
          </Text>
        </Box>
      )}
      <Box top="10px" zIndex="2" mb="10px">
        <Text fontSize="md" fontWeight="bold">
          {t("table:additional_file_upload_details")}
        </Text>
      </Box>
      <Box width="100%" mt="10px" marginBottom="20px">
        <VStack space={5}>
          <Box width="300px">
            <Text bold mb="2px">
              Status
            </Text>
            <Select
              style={{ width: "100%" }}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              onChange={(e) => {
                setStatus(e);
                getAdditionalUserExcel(current, limit, e);
              }}
            >
              {status_list?.map((item) => {
                return <Option value={item.value}>{item.name}</Option>;
              })}
            </Select>
          </Box>
          <HStack justifyContent="flex-end" space={"5"}>
            {role !== "Society" && (
              <Button type="default" danger onClick={handleBack}>
                Back
              </Button>
            )}
          </HStack>
          {id || role === "Admin" ? (
            ""
          ) : (
            <HStack space={3} justifyContent="flex-end">
              <HStack key={`header-action`} space={3}>
                <Button
                  style={{
                    height: "45px",
                  }}
                  onClick={() => handleAdd()}
                  color="#158d94"
                  background={"#158d94"}
                >
                  Upload
                </Button>
              </HStack>
              {/* <Box key={`header-action1`}>
                <Button
                  style={{
                    height: "45px",
                  }}
                  onClick={() => {
                    fetchDataFromExcel();
                  }}
                  color="#158d94"
                  background={"#158d94"}
                >
                  Excel
                </Button>
              </Box> */}
              <Box key={`header-action`}>
                <Button
                  style={{
                    height: "45px",
                  }}
                  onClick={() => {
                    window.open(
                      "https://v-ecs-migration.blr1.vultrobjects.com/f718dc87-c01c-477f-98b6-f07af364862a.xlsx"
                    );
                  }}
                  // onClick={handleAdd}
                  color="#158d94"
                  background={"#158d94"}
                >
                  Sample Excel
                </Button>
              </Box>
            </HStack>
          )}
          <Wrapper>
            <Table
              loading={additional_loading}
              columns={columns}
              dataSource={additional_sheet_list}
              pagination={false}
            />
          </Wrapper>
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={fetchData}
          />
        </VStack>
      </Box>
      <AntdModal
        isOpen={report_modal_open}
        onClose={handleReportClose}
        width={1000}
        footer={null}
        header={t("Reports")}
        component={
          <Reports
            close={handleReportClose}
            initialValues={action_item}
            is_society={true}
          />
        }
      />
      <AntdModal
        isOpen={model_open}
        onClose={handleModalClose}
        width={450}
        header={"File Upload"}
        component={
          <AdditionalFileUploadDetails
            label={t("add")}
            close={handleModalClose}
            form={form}
            getAdditionalUserExcel={getAdditionalUserExcel}
          />
        }
      />
    </>
  );
};
export default AdditionalFileUploadList;
